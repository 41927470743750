.btn-container {
    padding: 8px 24px 8px 24px;
    background: #667ab3;
    color: #fff;
    font-weight: bold;
    border-radius: 6px;
    font-family: 'Inter', sans-serif;
}

.btn-container:hover {
    background: rgba(102, 122, 179, 0.9);
    /* border: 0.00001px solid rgba(102, 122, 179, 0.9); */
}

.btn-default {
    background: #fff !important;
    border: 1px solid #d1d5dc !important;
    color: #000 !important;
}

.btn-default:hover {
    background: #efefef !important;
}

.button-disabled {
    background: rgba(102, 122, 179, 0.5);
}

.button-disabled:hover {
    background: rgba(102, 122, 179, 0.55);
}
