.layout {
  color: var(--txt-color);
}

.layout__content {
  padding-left: var(--sidebar-width);
  /* background-color: var(--second-bg); */
  min-height: 100vh;
  background: white;
}

.layout__content-main {
  padding-top: 80px;
  padding-left: 30px;
  padding-right: 30px;
  min-height: 100vh;
}

@media (max-width: 720px) {
  .layout__content {
    padding-left: 0px;
  }
}
