.title-catridge-rekonsiliasi {
    margin-bottom: 20px;
}

.title-card-catridge-rekonsiliasi {
    margin-bottom: 20px;
    font-family: 'Inter', sans-serif;
}

.container-catridge-rekonsiliasi {
    margin-bottom: 20px;
}

.card-catridge-rekonsiliasi {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
}

.nomorseri-catridge-rekonsiliasi {
    margin-bottom: 10px;
}

.hr-catridge-rekonsiliasi {
    margin-bottom: 15px;
    width: 100%;
    border-top: 1px solid #E4E7EB;
}