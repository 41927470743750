.status-label {
    border-radius: 6px;
    padding: 2px 5px;
    display: inline-flex;
    align-items: center;
}

.status-label-circle {
    width: 8px;
    height: 8px;
    border-radius: 8px;
}

.status-label-text {
    margin-left: 5px; 
    font-size: 14px;
    font-family: 'Inter', sans-serif;
}

.status-label-recon-text {
    font-size: 13px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    color: #1A3650;
}

.status-label-process {
    border: 1px solid #687083;
    background: #F9FAFB;
}

.status-label-error {
    border: 1px solid red;
    background: #FCF3F2;
}

.status-label-success {
    border: 1px solid #8DE5AB;
    background: #EFFCF4;
}

.status-label-circle-process {
    background: #687083;
}

.status-label-circle-error {
    background: red;
}

.status-label-circle-success {
    background: green;
}

.status-label-text-process {
    color: #687083;
}

.status-label-text-error {
    color: red;
}

.status-label-text-success {
    color: green;
}