.title-input-branch {
    margin-bottom: 20px;
    font-size: 24px;
    font-family: 'Inter', sans-serif;
}

.row-between {
    justify-content: space-between;
    flex-direction: row;
}

.padding-vertical-20 {
    padding-right: 20px;
    padding-left: 20px;
}

.padding-vertical-10 {
    padding-right: 10px;
    padding-left: 10px;
}

.bottom-content {
    margin-top: 20px;
    text-align: right;
    flex-direction: row;
}

.spacing {
    width: 20px;
}
