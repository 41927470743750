.container404 {
    min-height: 80vh;
    align-items: center;
    justify-content: center;
}

.card-container404 {
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 30px;
    justify-content: center;
    background-color: var(--main-bg);
    border: 0.8px solid var(--border-color);
    align-items: center;
    align-self: center;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    min-height: 60vh;
}

.text404 {
    font-weight: bold;
    font-size: 60pt;
    color: #667AB3;
    font-family: 'Inter', sans-serif;
}

.not-found-text404 {
    color: #000;
    font-size: 12pt;
    font-weight: bold;
    margin-bottom: 20px;
    font-family: 'Inter', sans-serif;
}

.description404 {
    font-size: 12pt;
    margin-bottom: 20px;
    font-family: 'Inter', sans-serif;
}

.btn404 {
    padding: 10px 20px 10px 20px;
    min-width: 200px;
    background: #667AB3;
    color: #FFF;
    font-weight: bold;
    border-radius: 6px;
    font-family: 'Inter', sans-serif;
}