.title-input-atm {
    margin-bottom: 20px;
    font-size: 18px;
    font-family: 'Inter', sans-serif;
}

.btn-container-input-atm {
    margin-top: 20px;
    margin-bottom: 10px;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-end;
}