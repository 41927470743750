.status-card {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    max-height: 130px;
    height: 130px;
    display: -ms-flexbox;
    display: flex;
    /* align-items: center; */
    background-color: #F9FAFB;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: color 0.5s ease 0s;
    margin-bottom: 30px;
}

.status-card-info {
    flex-grow: 1;
    text-align: start;
    z-index: 1;
    text-transform: capitalize;
}

.status-card-title-container {
    margin-bottom: 10px;
}

.status-card-title {
    font-size: 16px;
    color: black;
    font-family: 'Inter', sans-serif;
}

.status-card-title.active {
    color: white;
}

.status-card-info > h4 {
    font-size: 35px;
    font-family: 'Inter', sans-serif;
    color: black;
}

.status-card-info > h4.active {
    color: white;
}

.status-card::before {
    content: "";
    width: 100%;
    padding-top: 100%;
    border-radius: 50%;
    background-image: linear-gradient(
        to top right,
        #667AB3,
        rgb(121, 140, 190)
        
    );
    position: absolute;
    left: -50%;
    top: 0;
    transform: scale(0);
    transition: transform 0.2s ease 0s;
}

.status-card:hover::before {
    transform: scale(3);
}

.status-card:hover {
    color: var(--txt-white);
}

.status-card:hover .status-card-info > h4, .status-card:hover .status-card-info > .status-card-title-container > span {
    color:white;
}

.status-card.active {
    background: #667AB3;
}

.status-card.active:hover::before, .status-card.active::before {
    transform: scale(0);
}