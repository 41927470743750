.sidebar {
    min-width: var(--sidebar-width);
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    /* background-color: var(--main-bg); */
    box-shadow: var(--box-shadow);
    z-index: 20;
    background: #F9FAFB;
    -webkit-transition: transform 0.3s ease-out;
    -moz-transition: transform 0.3s ease-out;
    -o-transition: transform 0.3s ease-out;
    -ms-transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
}

.sidebar-logo {
    /* height: 100px; */
    display: flex;
    /* align-items: center; */
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 0px;
}

.sidebar-logo > img {
    /* height: 105px; */
    height: 55px;
    margin-bottom: 20px;
}

.sidebar__item {
    padding: 0 20px;
}

.sidebar__item-inner {
    padding: 10px 10px;
    display: flex;
    align-items: center;
    font-weight: 600;
    transition: color 0.3s ease 0s;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
}

.sidebar__item-inner > i {
    margin-right: 10px;
    font-size: 1.5rem;
}

.sidebar__item-inner > span {
    text-transform: capitalize;
}

.sidebar__item-inner:hover {
    /* color: var(--main-color); */
    color: #667AB3;
}

.sidebar__item-inner.active {
    border-radius: 6px;
    background: #667AB3;
    color: var(--txt-white);
}

@media (max-width: 720px) {

    /* .Open {
        transform: translateX(0);
    } */

    .sidebar {
        transform: translateX(-100%);
    }

    .sidebar-show {
        transform: translateX(0);
    }

    .sidebar-hide {
        transform: translateX(-100%);
    }
}
