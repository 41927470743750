.dropdown {
    /* margin-bottom: 200px; */
    
}

.dropdown-header {
    border: 1px solid #D1D5DC;
    border-radius: 6px;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropdown-value {
    padding: 10px 10px;
    color: #111827;
}

.dropdown-chevron {
    padding-right: 10px;
    margin-top: 2px;
}

.dropdown-list {
    border: 1px solid #D1D5DC;
    position: absolute;
    max-height: 180px;
    overflow-y: scroll;
    transform-origin: top left;
    transform: scale(1);
    transition: transform 0.3s ease 0s;
    background: #FFFFFF;
    z-index: 10;
    -ms-overflow-style: none;
}

.dropdown-list::-webkit-scrollbar {
    display: none;
}

.dropdown-list.active {
    transform: scale(1);
    transition: transform 0.5s var(--transition-cubic);
}

.dropdown-listitem {
    border-bottom: 1px solid #D1D5DC;
    padding: 10px 10px;
}

.dropdown-listitem:hover {
    background: #efefef;
    cursor: pointer;
}

.dropdown-header:hover {
    cursor: pointer;
}

.dropdown-list-inside-table {
    border: 1px solid #D1D5DC;
    /* position: -webkit-sticky; */
    position: absolute;
    right: 0px;
    /* top: 0; */
    /* left: -1000; */
    max-height: 180px;
    /* overflow-y: scroll; */
    overflow: unset;
    transform-origin: top left;
    transform: scale(1);
    transition: transform 0.3s ease 0s;
    background: #FFFFFF;
    z-index: 10 !important;
    /* -ms-overflow-style: none; */
}