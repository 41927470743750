.card-item {
    border: 1px solid #E4E7EB;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 6px;
}

.card-item-header {
    border: 1px solid #E4E7EB;
    padding-bottom: 20px;
    border-radius: 6px;
}

.card-header {
    padding: 8px 10px;
    background: #E4E7EB;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-bottom: 10px;
}

.card-header-primary {
    background: #F0F8FE!important;
}

.card-header-success {
    background: #EFFCF4 !important;
}

.card-header-title {
    font-size: 14px;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
}

.card-header-title-primary {
    color: #295ECC;
}

.card-header-title-success {
    color: #34774C;
}