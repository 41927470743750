.alert-container {
    display: none;
    position: fixed;
    z-index: 9999;
    padding-top: 120px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.alert-container.active {
    display: block;
}

.alert-content {
    text-align: center;
    border-radius: 10px;
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 30%;
    max-height: 450px;
    min-height: 200px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation: fadeInFromNone 0.3s ease-out;
    -moz-animation: fadeInFromNone 0.3s ease-out;
    -o-animation: fadeInFromNone 0.3s ease-out;
    animation: fadeInFromNone 0.3s ease-out;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.alert-image {
    width: 120px;
    height: 120px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.alert-message {
    font-size: 18px;
    font-weight: bold;
    margin: 20px 10px;
    font-family: 'Inter', sans-serif;
    text-align: center;
}

.alert-button {
    margin-top: 10px;
    margin-bottom: 20px;
    gap: 16px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

@media (max-width: 720px) {
    .alert-content {
        width: 60%;
    }
}

@media (max-width: 500px) {
    .alert-content {
        width: 85%;
    }
}
