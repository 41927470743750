.btn-status:hover {
    cursor: pointer; 
}

.page-dashboard {
    min-height: 100vh;
    margin-bottom: 150px;
}

.title-dashboard {
    font-family: 'Inter', sans-serif;
}

.id-dashboard {
    color: #4991F2;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
}

.id-dashboard:hover {
    cursor: pointer;
}

.selisih-dashboard {
    color: red;
}

.modal-content-date {
    border-radius: 5px;
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    max-height: 450px;
    overflow: scroll;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation: fadeInFromNone 0.3s ease-out;
    -moz-animation: fadeInFromNone 0.3s ease-out;
    -o-animation: fadeInFromNone 0.3s ease-out;
    animation: fadeInFromNone 0.3s ease-out;
    -ms-overflow-style: none;
}

.dashboard-action-icon {
    padding: 2px;
    background: #667ab3;
    border-radius: 4px;
}

.dashboard-action-icon:hover {
    cursor: pointer;
}