.container-confirmation {
    min-height: 100vh;
    align-items: center;
    justify-content: center;
}

.input-container-confirmation:focus-within {
    border: 1px solid #000;
}

.input-text-confirmation {
    font-size: 12pt;
}

.input-text-confirmation {
    border: none;
    height: 100%;
}

.input-text-confirmation:focus{
    outline: none;
    border: none;
}


.logo-input-confirmation {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 5px;
}

.icon-confirmation {
    color: white;
    min-width: 40px;
    text-align: center;
    height: 100%;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.input-container-confirmation {
    display: -ms-flexbox;
    display: flex;
    border: 1px solid #D1D5DC;
    border-radius: 6px;
    margin-bottom: 15px;
    height: 35px;
    padding: 0px 10px 0px 0px;
    align-items: center;
    width: 85%;
}

.card-confirmation {
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 30px;
    justify-content: center;
    background-color: var(--main-bg);
    border: 0.8px solid var(--border-color);
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    min-width: 350px;
}

.title-confirmation {
    font-weight: bold;
    font-size: 20pt;
    font-family: var(--font-medium);
    margin-bottom: 15px;
}

.subtitle-confirmation {
    margin-bottom: 30px;
    font-family: var(--font-regular);
    font-size: 12pt;
    text-align: center;
    color: #687083;
}
