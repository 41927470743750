.header-lapor-inisiasiulang {
    margin-bottom: 20px;
    font-family: 'Inter', sans-serif;
}

.container-lapor-inisiasiulang {
    margin-bottom: 20px;
}

.card-detail-replenishment {
    padding-left: 20px;
    padding-right: 20px;
}

.info-detail-replenishment {
    margin-bottom: 5px;
}

.label-lapor-inisiasiulang {
    color: #1A3650;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    font-family: 'Inter', sans-serif;
}

.value-lapor-inisiasiulang {
    color: #092540;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
}

.header-table-detail-replenishment {
    display: flex;
    align-items: center;
}

.header-dropdown-detail-replenishment {
    margin: 0 10px;
}

.hr-detail-replenishment {
    margin: 15px 0;
    width: 100%;
    border-top: 1px solid #E4E7EB;
}

.text-red-detail-replenishment {
    color: #DE5242;
}

.a-label-lapor-inisiasiulang {
    margin-left: 10px;
    color: #DE5242;
}

.a-label-lapor-inisiasiulang:hover {
    cursor: pointer;
    text-decoration: underline;
}

.info-right-detail-replenishment {
    margin-bottom: 15px;
}

.total-lapor-inisiasiulang {
    font-weight: 700;
    font-size: 16px;
    font-family: 'Inter', sans-serif
}

.upload-label-lapor-inisiasiulang {
    font-family: 'Inter', sans-serif;
    margin-bottom: 10px;
    font-size: 13px;
}