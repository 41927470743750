.catridge-modal {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
}

.nomorseri-catridge-modal {
    margin-bottom: 10px
}

.label-catridge-modal {
    color: #1A3650;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    font-family: 'Inter', sans-serif;
}

.value-catridge-modal {
    color: #092540;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
}