.title-charts {
    margin-top: 20px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #092540;
}

.date-title-charts {
    margin-top: 5px;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    text-align: center;
}