.topnav {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 5px;
    padding-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* height: var(--topnav-height); */
    background: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 1px 10px;
    
}

.topnav__left {
    position: relative;
    height: 50px;
    /* background-color: var(--main-bg); */
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    /* box-shadow: var(--box-shadow);
    border-radius: var(--border-radius); */
    overflow: hidden;
}

.topnav__right {
    display: flex;
    align-items: center;
}

.topnav__right-item ~ .topnav__right-item {
    margin-left: 30px;
}

.notification-item {
    display: flex;
    align-items: center;
    padding: 20px;
}

.notification-item:hover {
    background-color: var(--second-bg);
}

.notification-item > i {
    margin-right: 20px;
    font-size: 1.5rem;
}

.topnav__right-user {
    display: flex;
    align-items: center;
}

.topnav__right-user__image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
}

.topnav__right-user__image > img {
    width: 100%;
}

.topnav_user {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}

.topnav_user_name {
    font-size: 16px;
    text-align: start;
    align-items: flex-start;
    color: #000;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}

.topnav_user_role {
    font-size: 13px;
    color: #687083;
    text-align: start;
    align-items: flex-start;
    font-family: 'Inter', sans-serif;
}

.menu-item {
    padding: 10px 20px 10px 20px;
    min-width: 150px;
}

.menu-item-name {
    font-family: 'Inter', sans-serif;
    
}
