.container-login {
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    display: -ms-flexbox;
    display: flex;
}

.input-container-login:focus-within {
    border: 1px solid #000;
}

.input-text-login {
    font-size: 16px;
    border: none;
    height: 100%;
    width: 100%;
    font-family: 'Inter', sans-serif;
}

.input-text-login:focus{
    outline: none;
    border: none;
}

.icon-container-login {
    color: white;
    min-width: 40px;
    text-align: center;
    height: 100%;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    align-items: center;
    justify-content: center;
    display: -ms-flexbox;
    display: flex;
}

.input-container-login {
    display: -ms-flexbox;
    display: flex;
    border: 1px solid #D1D5DC;
    border-radius: 6px;
    margin-bottom: 15px;
    height: 38px;
    padding: 0px 10px 0px 0px;
    align-items: center;
    width: 85%;
}

.card-login {
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 30px;
    justify-content: center;
    background-color: var(--main-bg);
    border: 0.8px solid var(--border-color);
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    min-width: 350px;
}

.title-login {
    font-weight: 500;
    font-size: 23px;
    font-family: 'Inter', sans-serif;
    margin-bottom: 15px;
    text-align: center;
}

.subtitle-login {
    margin-bottom: 30px;
    font-family: 'Inter', sans-serif;
    font-size: 12pt;
    text-align: center;
    color: #687083;
}

.forgot-password-login {
    color: #E66B14;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.logo-container-login {
    position: absolute;
    top: 20px;
    left: 50% - 73px;
    height: 48px;
    width: 143px;
}

.logo-login {
    /* height: 120px; */
    width: 143px;
    margin-bottom: 20px;
}


.forgot-password-login:hover {
    text-decoration: underline;
    cursor: pointer;
}

.btn-login {
    width: 85%;
    margin-top: 15px; 
    padding-bottom: 12px;
    padding-top: 12px;
    border-radius: 6px;
}

.container-hide-show-login {
    color: white;
    min-width: 40px;
    text-align: center;
    height: 100%;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    align-items: center;
    justify-content: center;
    display: -ms-flexbox;
    display: flex;
}

.hide-show-login {
    color: #1890ff;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
}

.hide-show-login:hover {
    cursor: pointer;
}