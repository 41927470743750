.modal {
    display: none;
    position: fixed;
    z-index: 9999;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}

.modal.active {
    display: block;
}

.modal-content {
    border-radius: 5px;
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 50%;
    max-height: 450px;
    overflow: scroll;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation: fadeInFromNone 0.3s ease-out;
    -moz-animation: fadeInFromNone 0.3s ease-out;
    -o-animation: fadeInFromNone 0.3s ease-out;
    animation: fadeInFromNone 0.3s ease-out;
    -ms-overflow-style: none;
}

.modal-content::-webkit-scrollbar {
    display: none;
}


@-webkit-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@-moz-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@-o-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

.modal-header {
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    border-bottom: 1px solid #ccc;

}

.modal-title {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
}

.modal-close:hover {
    cursor: pointer;
}

.modal-body {
    padding: 15px;
}

@media (max-width: 720px) {
    .modal-content{
        width: 60%;
    }
}

@media (max-width: 500px){
    .modal-content{
        width: 95%;
    }
}
