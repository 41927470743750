.dropzone-doc {
    width: 100%;
}

.label-dropzone {
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    margin-bottom: 10px;
}

.label-container-dropzone {
    font-size: 14px;
    color: #000;
    font-family: 'Inter', sans-serif;
    text-align: center;
    margin: 0px 10px;
}

.icon-dropzone {
    margin-bottom: 15px;
}

.container-dropzone-doc {
    /* height: 150px; */
    width: 100%;
    /* background: #F9FAFB; */
    border: 1px solid #E4E7EB;
    border-radius: 6px; 
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    cursor: pointer;
}

.file-dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
}

.typefile-dropzone{
    padding: 1px 0px;
    font-size: 10px;
    background: #667ab3;
    color: white;
    text-align: center; 
    font-weight: bold;
    width: 32px; 
    position: absolute; 
    margin-top: 24px;
    text-transform: uppercase;
}

.namefile-dropzone {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-family: 'Inter', sans-serif;
    text-align: center;
}

.sizefile-dropzone {
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    margin-bottom: 10px;
    font-family: 'Inter', sans-serif;
}

.viewfile-dropzone {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    color: #667ab3;
    margin-right: 10px;
}

.viewfile-dropzone:hover, .deletefile-dropzone:hover {
    cursor: pointer;
    text-decoration: underline;
}

.deletefile-dropzone {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    color: red;
}