.container-sent-email {
    min-height: 100vh;
    align-items: center;
    justify-content: center;
}

.card-sent-email {
    /* background: white; */
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 30px;
    justify-content: center;
    background-color: var(--main-bg);
    /* box-shadow: var(--box-shadow); */
    /* border-radius: var(--border-radius); */
    border: 0.8px solid var(--border-color);
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    /* direction: column; */
    flex-direction: column;
    /* background: #fbfbfb; */
    /* border-radius: 8px; */
    /* box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.65); */
    /* height: 410px; */
    /* margin: 6rem auto 8.1rem auto; */
    /* width: 329px; */
    max-width: 400px;
    min-width: 350px;
}

.title-sent-email {
    font-weight: bold;
    font-size: 25px;
    font-family: 'Inter', sans-serif;
    margin-bottom: 15px;
    text-align: center;
}

.subtitle-sent-email {
    margin-bottom: 30px;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    text-align: center;
    color: #687083;
}

.resend-label-sent-email {
    font-family: 'Inter', sans-serif;
    font-size: 10pt;
    text-align: center;
    color: #687083;
    margin-bottom: 10px;
}

.resend-button-sent-email {
    color: #E66B14;
    font-size: 10pt;
    font-family: 'Inter', sans-serif;
}

.resend-button-sent-email:hover {
    cursor: pointer;
    text-decoration: underline;
}

.logo-container-sent-email {
    position: absolute;
    top: 10px;
    left: 20px;
    display: -ms-flexbox;
    display: flex;
    height: 48px;
    width: 143px;
}

.logo-sent-email {
    /* height: 120px; */
    width: 143px;
}

.img-verification-sent-email {
    width: 40%;
    height: 40%;
    margin-bottom: 20px;
}
