.title-input-user {
    margin-bottom: 20px;
    font-size: 18px;
    font-family: 'Inter', sans-serif;
}

.btn-container-input-user {
    margin-top: 20px;
    margin-bottom: 10px;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-end;
}

.textinput-label-input-user {
    margin-bottom: 6px;
}

.textinput-hide-show-btn {
    color: #1890ff;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
}

.textinput-hide-show-btn:hover {
    cursor: pointer;
}