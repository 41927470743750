.spinner-loader-container {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.1);
    
    align-items: center;
    justify-content: center;
}

.spinner-loader-enabled {
    display: -ms-flexbox;
    display: flex;
}

.spinner-loader-disabled {
    display: none;
}

.spinner-loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #3498db;
    border-left: 8px solid #3498db;
    border-right: 8px solid #3498db;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 0.5s linear infinite; /* Safari */
    animation: spin 0.5s linear infinite;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}