.detail-atm {
    margin-bottom: 150px;
}

.header-detail-atm {
    margin-bottom: 20px;
    font-family: 'Inter', sans-serif;
}

.container-detail-atm {
    margin-bottom: 20px;
}

.card-detail-atm {
    padding-left: 20px;
    padding-right: 20px;
}

.info-detail-atm {
    margin-bottom: 10px;
}

.label-detail-atm {
    color: #1A3650;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    font-family: 'Inter', sans-serif;
}

.value-detail-atm {
    color: #092540;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
}

.header-table-detail-atm {
    display: -ms-flexbox;
    display: flex;
    align-items: center;
}

.header-dropdown-detail-atm {
    margin: 0 10px;
}

.btn-detail-update {
    width: 100%;
    margin-top: 20px;
    padding: 12px 20px;
}

.id-replenisment-detail-atm {
    text-align: left;
    color: #4991F2;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    float: left;
}

.id-replenisment-detail-atm:hover {
    cursor: pointer;
}

.selisih-replenisment-detail-atm {
    color: red;
}

/* @media only screen and (max-width: 910px) {
    .btn-detail-update {
        padding: 12px 10px;
    }
} */