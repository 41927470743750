.textinput-container {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    border: 1px solid #D1D5DC;
    border-radius: 6px;
    margin-top: 5px;
    margin-bottom: 5px;
    align-items: center;
    justify-content: center;
}

.textinput-label {
    font-size: 14px;
    color: #000;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
}

.textinput {
    display: -ms-flexbox;
    display: flex;
    flex: 1;
    border: none;
    padding-top: 5px;
    padding-bottom: 5px;
    padding: 0px 10px 0px 10px;
    margin: 5px 0px 5px 0px;
    font-family: 'Inter', sans-serif;
    width: 100%;
}

.textinput:focus {
    outline: none;
    border: none;
}

.textinput::-webkit-outer-spin-button,
.textinput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.textinput[type=number] {
  -moz-appearance: textfield;
}

.textinput-lefticon {
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    margin-left: 10px;
    
}

.textinput-righticon {
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    margin-right: 10px;
}

.textinput-read-only {
    background: #F9FAFB;
}

.textinput-container-error {
    border-color: red;
}

.textinput-message-error {
    color: red;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
}
