.header-detail-rekonsiliasi {
  margin-bottom: 20px;
  font-family: 'Inter', sans-serif;
}

.container-detail-rekonsiliasi {
  margin-bottom: 20px;
}

.card-detail-rekonsiliasi {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
}

.info-detail-rekonsiliasi {
  margin-bottom: 10px;
}

.label-detail-rekonsiliasi {
  color: #1a3650;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  font-family: 'Inter', sans-serif;
}

.value-detail-rekonsiliasi {
  color: #092540;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
}

.header-table-detail-rekonsiliasi {
  display: flex;
  align-items: center;
}

.header-dropdown-detail-rekonsiliasi {
  margin: 0 10px;
}

.hr-detail-rekonsiliasi {
  margin: 15px 0;
  width: 100%;
  border-top: 1px solid #e4e7eb;
}

.text-red-detail-rekonsiliasi {
  color: #de5242;
}

.a-label-detail-rekonsiliasi {
  margin-left: 10px;
  color: #de5242;
}

.a-label-detail-rekonsiliasi:hover {
  cursor: pointer;
  text-decoration: underline;
}

.info-right-detail-rekonsiliasi {
  margin-bottom: 15px;
}

.card-info-rekon-doc {
  width: 242px;
  height: 122px;
  background: #f9fafb;
  border-radius: 6px;
  padding: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.title-info-rekon-doc {
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  margin-left: 10px;
  font-size: 18px;
  color: #092540;
  /* margin-bottom: 20px; */
}

.value-info-rekon-doc {
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  margin-left: 10px;
  font-size: 45px;
  color: #092540;
  /* margin-top: 20px; */
}

.amount-info-rekon-doc {
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  margin-left: 10px;
  font-size: 30%;
  color: #092540;
  /* margin-top: 30px; */
}

.title-failed-trx {
  color: #de5242;
  margin-top: 25px;
  /* margin-bottom: 15px; */
  position: absolute;
  right: 40px;
  cursor: pointer;
}
.title-failed-trx p {
  margin-right: 5px;
}
.action-table {
  align-items: center;
  margin-left: -15px;
  padding: 0 30px;
  justify-content: space-between;
}

.action-table div {
  align-items: center;
}

.title-rekon-doc {
  font-family: 'Inter', sans-serif;
  color: #092540;
  font-weight: bold;
}

.back-rekon-doc {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.arrow-back-rekon-doc {
  margin-right: 5px;
}

.label-back-rekon-doc {
  font-family: 'Inter', sans-serif;
  color: #667ab3;
  font-weight: 600;
}

.back-rekon-doc:hover {
  cursor: pointer;
}

.rekon-atm-alasan:hover {
  cursor: pointer;
}
