.button-tab-container {
    display: inline-block;
}

.button-tab {
    padding: 8px 12px 8px 12px;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: color 0.5s ease 0s;
    
}

.button-tab-info {
    flex-grow: 1;
    text-align: start;
    z-index: 1;
    text-transform: capitalize;
}

.button-tab-info > span {
    color: black;
}

.button-tab-info > span.active {
    color: #667AB3;
}

.button-tab::before {
    content: "";
    width: 100%;
    padding-top: 100%;
    border-radius: 50%;
    background-image: linear-gradient(
        to top right,
        #E7EAF3,
        #E7EAF3
    );
    position: absolute;
    left: -50%;
    top: 0;
    transform: scale(0);
    transition: transform 0.2s ease 0s;
}

.button-tab:hover::before {
    transform: scale(8);
    cursor: pointer;
}

.button-tab:hover {
    color: var(--txt-white);
    cursor: pointer;
}

.button-tab:hover .button-tab-info > span {
    color:#667AB3;
    cursor: pointer;
}

.button-tab.active {
    background: #E7EAF3;
    cursor: pointer;
}

.button-tab.active:hover::before, .button-tab.active::before {
    transform: scale(0);
    cursor: pointer;
}

.button-tab-label {
    font-size: 14px;
    font-weight: bold;
    font-family: 'Inter', sans-serif;
}