.table-container {
    margin: 15px 0px;
}

.styled-table {
    border-collapse: collapse;
    margin: 10px 0;
    font-size: 0.9em;
    font-family: sans-serif;
     /*min-width: 100%;*/
    min-width: 100%;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); */
    border: 1px solid #dddddd;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    overflow-y: auto;
    /* overflow: auto; */
}

.styled-table thead tr {
    background-color: #F9FAFB;
    color: #687083;
    text-align: left;
    font-family: 'Inter', sans-serif;
    border-bottom: 1px solid #dddddd;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

@media (min-width:1025px) {
    .styled-table {
        display: table;
    }
}

.styled-table tbody tr {
    border-bottom: 0.5px solid #dddddd;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
}

.styled-table tbody tr:nth-of-type(even) {
    /* background-color: #f3f3f3; */
}

.styled-table tbody tr:last-of-type {
    /* border-bottom: 2px solid #687083; */
    border-bottom: 0px solid #dddddd;
}
.styled-table tbody tr.active-row {
    font-weight: bold;
    color: #687083;
}

.table-header, .table-footer {
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.table-limit-number, .table-pagination {
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.table-limit-label {
    font-size: 14px;
    margin-right: 20px;
    font-family: 'Inter', sans-serif;
}

.table-pagination {
    justify-content: flex-end;
    float: right;

}

.table-pagination-numbers {
    margin-left: 15px;
}

.page-arrow-btn:hover {
    cursor: pointer;
}

.page-arrow-btn-left {
    margin-left: 0px;
    margin-right: 25px;
}

.table-sticky-header-left {
    position: -webkit-sticky;
    position: sticky;
    background: #F9FAFB;
    left: 0px;
}

.table-sticky-header-right {
    position: -webkit-sticky;
    position: sticky;
    background: #F9FAFB;
    right: 0px;
}

.table-sticky-body-left {
    position: -webkit-sticky;
    position: sticky;
    background: #FFF;
    left: 0px;
}

.table-sticky-body-right {
    position: -webkit-sticky;
    position: sticky;
    background: #FFF;
    right: 0px;
}

.table-header-th {

}

.table-header-th-onclick {
    cursor: pointer;
}

.table-header-th-onclick:hover {
    color: #667ab3;
}
