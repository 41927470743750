.container-catridge-form{
    margin-bottom: 20px;
}

.label-catridge-form {
    color: #1A3650;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    font-family: 'Inter', sans-serif;
}

.value-catridge-form {
    color: #092540;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
}

.card-catridge-form {
    padding-left: 20px;
    padding-right: 20px;
}

.hr-catridge-form {
    margin: 15px 0;
    width: 100%;
    border-top: 1px solid #E4E7EB;
}

.header-catridge-form {
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.btn-del-catridge-form {
    color: #DE5242;
    font-size: 14px;
}

.btn-del-catridge-form:hover {
    cursor: pointer;
    text-decoration: underline;
}

.lembar-span-catridgeform {
    font-size: 14px;
    color: #9AA2B1;
    font-family: 'Inter', sans-serif;
}