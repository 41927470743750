.container-new-password {
    min-height: 100vh;
    align-items: center;
    justify-content: center;
}

.input-container-new-password:focus-within {
    border: 1px solid #000;
}

.input-text-new-password {
    font-size: 12pt;
    border: none;
    height: 100%;
    width: 100%;
}

.input-text-new-password:focus{
    outline: none;
    border: none;
}


.logo-input-new-password {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 5px;
}

.icon-container-new-password {
    color: white;
    min-width: 40px;
    text-align: center;
    height: 100%;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    align-items: center;
    justify-content: center;
    display: -ms-flexbox;
    display: flex;
}

.input-container-new-password {
    display: -ms-flexbox;
    display: flex;
    border: 1px solid #D1D5DC;
    border-radius: 6px;
    margin-bottom: 15px;
    height: 35px;
    padding: 0px 10px 0px 10px;
    align-items: center;
    width: 85%;
}

.card-new-password {
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 30px;
    justify-content: center;
    background-color: var(--main-bg);
    border: 0.8px solid var(--border-color);
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    min-width: 350px;
}

.title-new-password {
    font-weight: 500;
    font-size: 23px;
    font-family: 'HelveticaNeue';
    margin-bottom: 15px;
}

.subtitle-new-password {
    margin-bottom: 30px;
    font-family: 'HelveticaNeue';
    font-size: 14px;
    text-align: center;
    color: #687083;
}

.logo-container-new-password {
    position: absolute;
    top: 10px;
    left: 20px;
    height: 48px;
    width: 143px;
}

.logo-new-password {
    /* height: 120px; */
    width: 143px;
}

.btn-new-password {
    width: 85%;
    margin-top: 15px; 
    padding-bottom: 12px;
    padding-top: 12px;
    border-radius: 6px;
    background: #111827;
}

.label-input-new-password {
    text-align: start;
    align-self: flex-start;
    font-size: 12px;
    font-weight: 700;
    margin-left: 30px;
    margin-bottom: 10px;
    font-family: 'HelveticaNeue';
}