.container-forgot-password {
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex;
    display: -ms-flexbox;
}

.input-container-forgot-password:focus-within {
    border: 1px solid #000;
}

.input-text-forgot-password {
    font-size: 12pt;
    border: none;
    height: 100%;
    width: 100%;
}

.input-text-forgot-password:focus{
    outline: none;
    border: none;
}


.logo-input-forgot-password {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 5px;
}

.icon-container-forgot-password {
    color: white;
    min-width: 40px;
    text-align: center;
    height: 100%;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    align-items: center;
    justify-content: center;
    display: -ms-flexbox;
    display: flex;
}

.input-container-forgot-password {
    display: -ms-flexbox;
    display: flex;
    border: 1px solid #D1D5DC;
    border-radius: 6px;
    margin-bottom: 15px;
    height: 35px;
    padding: 0px 10px 0px 0px;
    align-items: center;
    width: 85%;
}

.card-forgot-password {
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 30px;
    justify-content: center;
    background-color: var(--main-bg);
    border: 0.8px solid var(--border-color);
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    min-width: 350px;
}

.title-forgot-password {
    font-weight: 500;
    font-size: 25px;
    font-family: 'Inter', sans-serif;
    margin-bottom: 15px;
    text-align: center;
}

.subtitle-forgot-password {
    margin-bottom: 30px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    text-align: center;
    color: #687083;
}

.logo-container-forgot-password {
    position: absolute;
    top: 20px;
    left: 50% - 73px;
    height: 48px;
    width: 143px;
}

.logo-forgot-password {
    /* height: 120px; */
    width: 143px;
}

.btn-forgot-password {
    width: 85%;
    padding: 12px 0;
    margin-top: 15px;
}